import { useEffect, useState } from 'react';

import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { TOKEN_KEY } from 'constants/constants';
import request from 'services/api/request';
import { clearState, loadState } from 'services/statePersist/localStore';
import useLoginRedirect from './useLoginRedirect';

export type Client = {
  name: string;
  uid: string;
};

const useClients = () => {
  const config = useConfig();
  const loginRedirect = useLoginRedirect();
  const [data, setData] = useState<Client[] | undefined>();
  const [error, setError] = useState<unknown>();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      const storedAuthToken = loadState(TOKEN_KEY);
      if (storedAuthToken) {
        try {
          setLoading(true);
          const response = await request<Client[]>(
            'GET',
            config.apiUrl,
            `/v1/clients`,
            undefined,
            { Authorization: `Bearer ${storedAuthToken}` },
          );
          setData(response);
          setLoading(false);
        } catch (err) {
          clearState(TOKEN_KEY);
          loginRedirect();
          setError(err);
          setLoading(false);
        }
      }
    })();
  }, []);

  return { data, error, loading };
};

export default useClients;
