import { useHistory } from 'react-router-dom';

const useLoginRedirect = () => {
  const history = useHistory();
  const loginRedirect = () =>
    history.push(`login?redirect=${window.location.href}`);
  return loginRedirect;
};

export default useLoginRedirect;
