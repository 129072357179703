import React from 'react';
import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import c from 'classnames';
import copy from 'copy-to-clipboard';

import { Client } from './useClients';
import useTokenUpdate from './useTokenUpdate';
// import useSlackNotify from './useSlackNotify';

type Props = {
  client: Client;
};

// const MRV_TOKEN_SLACK_CHANNEL = 'C03G28SGRFS';

const useStyles = createUseStyles({
  root: {
    marginTop: 20,
    fontFamily: ["'Roboto'", 'sans-serif'],
  },
  clientName: {
    fontSize: '18px',
    fontWeight: 300,
  },
  row: {
    marginTop: 8,
    display: 'flex',
  },
  input: {
    flexGrow: 1,
    border: 'solid 2px grey',
    borderRadius: 5,
    margin: [0, 8],
    padding: [0, 8],
  },
  button: {
    width: 120,
    borderRadius: 5,
    lineHeight: '48px',
    color: '#fff',
  },
  buttonGenerate: {
    backgroundColor: '#269f92',
    backgroundImage: 'linear-gradient(90deg, #269F92 0%, #1fd0bd 100%)',
  },
  buttonCopy: {
    background: 'grey',
    width: 80,
  },
});

function TokenItem({ client }: Props) {
  const classes = useStyles();
  const { token, updateToken } = useTokenUpdate(client.uid);
  // const slackNotify = useSlackNotify();
  const handleGenerate = () => {
    updateToken();
    // .then(() =>
    //   slackNotify(MRV_TOKEN_SLACK_CHANNEL, {
    //     blocks: [
    //       {
    //         type: 'header',
    //         text: {
    //           type: 'plain_text',
    //           text: 'New token request',
    //           emoji: true,
    //         },
    //       },
    //       {
    //         type: 'section',
    //         fields: [
    //           {
    //             type: 'mrkdwn',
    //             text: '*Client:*\nPaid Time Off',
    //           },
    //           {
    //             type: 'mrkdwn',
    //             text: '*Created by:*\n<example.com|Fred Enriquez>',
    //           },
    //         ],
    //       },
    //     ],
    // }),
    // );
  };
  return (
    <section className={classes.root}>
      <h3 className={classes.clientName}>{client.name}</h3>
      <div className={classes.row}>
        <button
          className={c(classes.button, classes.buttonGenerate)}
          onClick={handleGenerate}
        >
          <FormattedMessage id="user.generate" defaultMessage="Generate" />
        </button>
        <input
          className={classes.input}
          disabled
          readOnly
          type="text"
          placeholder="Press generate"
          value={token}
        />
        <button
          className={c(classes.button, classes.buttonCopy)}
          onClick={() => token && copy(token)}
        >
          <FormattedMessage id="user.copyToken" defaultMessage="Copy" />
        </button>
      </div>
    </section>
  );
}

export default TokenItem;
