export const loadState = (key: string): any | undefined => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e: any) {
    console.error(e.message);
    return undefined;
  }
};

export const saveState = (key: string, value: any): boolean => {
  try {
    if (value !== undefined) {
      const serializedState = JSON.stringify(value);
      localStorage.setItem(key, serializedState);
      return true;
    }
    return false;
  } catch (e: any) {
    console.error(e.message);
    return false;
  }
};

export const clearState = (key: string): void => {
  localStorage.removeItem(key);
};

export default { loadState, saveState };
