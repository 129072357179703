import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { post } from './request';

export type RefreshType = {
  access_token: string;
  expires_in: string;
  token_type: string;
  refresh_token: string;
  id_token: string;
  user_id: string;
  project_id: boolean;
};

const useRefreshToken = () => {
  const { apiUrl } = useConfig();
  return {
    refresh: (refreshToken: string) =>
      post<RefreshType>(apiUrl, '/v1/login/refresh-token', {
        refresh_token: refreshToken,
      }),
  };
};

export default useRefreshToken;
