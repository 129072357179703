import React, { useEffect } from 'react';
import useRefreshToken from '../../services/api/useRefreshToken';
import { loadState, saveState } from '../../services/statePersist/localStore';
import {
  LOCALHOST,
  REFRESH_TOKEN_KEY,
  TOKEN_KEY,
  TRUSTED_HOSTS,
} from '../../constants/constants';
import hasTokenExpired from '../../services/user/hasTokenExpired';

type CommandType = 'get-token' | 'set-token';
type Command = { type: CommandType; data?: any };

function RefreshToken() {
  const { refresh } = useRefreshToken();

  useEffect(() => {
    window.addEventListener(
      'message',
      (event: MessageEvent<Command>) => {
        // verify message sender
        if (
          !event.origin.match(TRUSTED_HOSTS) &&
          !event.origin.startsWith(LOCALHOST)
        ) {
          return;
        }

        // handle commands from clients
        switch (event.data?.type) {
          case 'get-token': {
            if (!hasTokenExpired(loadState(TOKEN_KEY))) {
              refresh(loadState(REFRESH_TOKEN_KEY)).then(res => {
                saveState(TOKEN_KEY, res.id_token);
                saveState(REFRESH_TOKEN_KEY, res.refresh_token);
                event.source?.postMessage(
                  { type: 'set-token', data: res.id_token },
                  // @ts-ignore
                  event.origin,
                );
              });
            }
            break;
          }
          default:
            break;
        }
      },
      false,
    );
  }, []);

  return <div>Ready...</div>;
}

export default RefreshToken;
