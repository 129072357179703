import { useState } from 'react';
import { useConfig } from '@hummingbirdtechgroup/wings-config';

import { TOKEN_KEY } from 'constants/constants';
import request from 'services/api/request';
import { loadState, saveState } from 'services/statePersist/localStore';

type TokenResponse = {
  client_uid: string;
  token: string;
};

function useTokenUpdate(clientId: string) {
  const mrvTokenKey = `mrv-token-${clientId}`;
  const config = useConfig();
  const [token, setToken] = useState<string | undefined>(() =>
    loadState(mrvTokenKey),
  );
  const [loading, setLoading] = useState<boolean>(false);

  const updateToken = async () => {
    const storedAuthToken = loadState(TOKEN_KEY);
    try {
      setLoading(true);
      const response = await request<TokenResponse>(
        'GET',
        config.apiUrl,
        `/v1/clients/${clientId}/api-token`,
        undefined,
        {
          Authorization: `Bearer ${storedAuthToken}`,
        },
      );
      setToken(response.token);
      saveState(mrvTokenKey, response.token);
      setLoading(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setLoading(false);
    }
  };
  return { token, updateToken, loading };
}

export default useTokenUpdate;
