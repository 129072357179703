import React from 'react';

type Props = {
  className?: string;
};

function HummingbirdLogo({ className }: Props): React.ReactElement {
  return (
    <svg className={className} viewBox="0 0 138.544 45">
      <path
        fill="#f47920"
        d="M0,6.01.708,8.884l19.1.865Z"
        transform="translate(0 -1.968)"
      />
      <path
        fill="#aa6cad"
        d="M22.009,4.283l-12,2.55L7.963,4.283Z"
        transform="translate(-2.607 -1.402)"
      />
      <path
        fill="#1b9cb6"
        d="M7.362,0l.4,2.88H21.812Z"
        transform="translate(-2.411)"
      />
      <path
        fill="#1b9cb6"
        d="M28.848,4.283l.406,4.9,7.124-.254Z"
        transform="translate(-9.446 -1.402)"
      />
      <path
        fill="#38bcab"
        d="M36.576,11.21l-7.124.242L38.8,14.989Z"
        transform="translate(-9.644 -3.71)"
      />
      <path
        fill="#dde21e"
        d="M40.007,11.21l2.248,3.778L44.5,12.547Z"
        transform="translate(-13.101 -3.71)"
      />
      <path
        fill="#1a77a7"
        d="M23.005,4.283l.406,4.9L11,6.833Z"
        transform="translate(-3.604 -1.402)"
      />
      <path
        fill="#42ba86"
        d="M1.052,10.284l19.1.865L5.7,15.387Z"
        transform="translate(-0.345 -3.405)"
      />
      <path
        fill="#2e98d4"
        d="M22.415,11.571,7.963,15.764,21.1,21.222Z"
        transform="translate(-2.607 -3.836)"
      />
      <path
        fill="#ce5f90"
        d="M28.811,11.571l9.347,3.536L27.5,21.222Z"
        transform="translate(-9.004 -3.836)"
      />
      <path
        fill="#1da4a8"
        d="M38.158,16.817,27.5,22.94l2.35,3.45Z"
        transform="translate(-9.004 -5.575)"
      />
      <path
        fill="#1d7cc1"
        d="M39.234,16.817l-8.453,9.674L41.487,22.5Z"
        transform="translate(-10.079 -5.576)"
      />
      <path
        fill="#61c199"
        d="M36.458,27.016l-7.582,2.815L24.963,40.779Z"
        transform="translate(-8.174 -8.971)"
      />
      <path
        fill="#f8ce37"
        d="M36.408,27.016,24.811,40.9l7.593-2.93Z"
        transform="translate(-8.125 -8.972)"
      />
      <path
        fill="#f9db36"
        d="M50.549,20.969,40.1,25.035,36.1,35.982l8.766-5.822Z"
        transform="translate(-11.821 -6.967)"
      />
      <path
        fill="#e19e4a"
        d="M44.873,34.581l-8.88,5.9,4.99-1.123Z"
        transform="translate(-11.786 -11.451)"
      />
      <path
        fill="#1dab4b"
        d="M49.968,6.61l-6.618,6.865,8.009-.081Z"
        transform="translate(-14.195 -2.165)"
      />
      <path
        fill="#7bc254"
        d="M53.19,6.61l7.084,3.667-5.694,3.117Z"
        transform="translate(-17.417 -2.165)"
      />
      <path
        fill="#198a4d"
        d="M43.349,16.786l8.009-.088L45.59,22.425Z"
        transform="translate(-14.195 -5.529)"
      />
      <path
        fill="#814d51"
        d="M52.449,16.7,46.68,22.425l7.334-2.855Z"
        transform="translate(-15.286 -5.529)"
      />
      <path
        fill="#8a6d3b"
        d="M55.258,16.7l1.565,2.873,6.165-2.792Z"
        transform="translate(-18.094 -5.525)"
      />
      <path
        fill="#5ebb52"
        d="M53.19,5.805l7.084,3.667L60,4.151Z"
        transform="translate(-17.417 -1.359)"
      />
      <path
        fill="#11a65e"
        d="M55.258,14.643l7.666.081.054-4.3Z"
        transform="translate(-18.094 -3.451)"
      />
      <path
        fill="#45b649"
        d="M66.7,10.424l-.079,4.3L69.8,11.889Z"
        transform="translate(-21.815 -3.451)"
      />
      <path
        fill="#a9d03e"
        d="M63.314,4.151l5.407,5.684L63.48,7.363Z"
        transform="translate(-20.733 -1.359)"
      />
      <path
        fill="#3e3c3b"
        d="M63.56,8.926l.11,2.109,2.027-1.1Z"
        transform="translate(-20.813 -2.923)"
      />
      <path
        fill="#7d4a4d"
        d="M68.724,9.841l7.559,1.7-5.79.158Z"
        transform="translate(-22.504 -3.222)"
      />
      <path
        fill="#0c8cba"
        d="M68.724,9.841,77.9,10.6l-1.615.942Z"
        transform="translate(-22.504 -3.222)"
      />
      <path
        fill="#806148"
        d="M81.578,10.967l2.19,1.483-3.805-.54"
        transform="translate(-26.184 -3.591)"
      />
      <path
        fill="#413f3d"
        d="M35.555,43.244l2.111,1.327-3.54.594Z"
        transform="translate(-11.175 -14.306)"
      />
      <path
        fill="#f47920"
        d="M27.61,43.244l-7.63,10.27-6.328,5.863L9.864,58.238Z"
        transform="translate(-3.23 -14.377)"
      />
      <path
        fill="#d77f28"
        d="M29.591,45.988,20.1,53.7l3.305.621Z"
        transform="translate(-6.582 -15.275)"
      />
      <path
        fill="#1b9cb6"
        d="M29.111,43.244,14.758,55.425l-.312-3.7Z"
        transform="translate(-4.731 -14.377)"
      />
      <path
        fill="#205883"
        d="M16.34,48.274V51l13.391-7.757Z"
        transform="translate(-5.351 -14.328)"
      />
      <path
        fill="#05051b"
        d="M98.271,28.1V24.663H94.1V28.1h-1.6V19.6h1.6v3.58h4.175V19.6h1.59v8.5Z"
        transform="translate(-30.288 -6.496)"
      />
      <path
        fill="#05051b"
        d="M107.067,23.319v3.132a1.427,1.427,0,0,0,1.444,1.6,1.577,1.577,0,0,0,1.529-1.675V23.319h1.469v6h-1.323l-.1-.813a2.662,2.662,0,0,1-2.027.9c-1.433,0-2.475-1.079-2.475-2.936V23.319Z"
        transform="translate(-34.575 -7.722)"
      />
      <path
        fill="#05051b"
        d="M120.313,29.249V26.042c0-.788-.413-1.5-1.227-1.5a1.357,1.357,0,0,0-1.275,1.5v3.205H116.33V23.254H117.7l.108.727a1.736,1.736,0,0,1,1.579-.825,1.8,1.8,0,0,1,1.773,1.117,2.163,2.163,0,0,1,2-1.092c1.7,0,2.536,1.044,2.536,2.84v3.228h-1.481V26.021c0-.788-.327-1.458-1.129-1.458a1.361,1.361,0,0,0-1.3,1.481v3.205Z"
        transform="translate(-38.093 -7.669)"
      />
      <path
        fill="#05051b"
        d="M136.481,29.249V26.042c0-.788-.413-1.5-1.227-1.5a1.356,1.356,0,0,0-1.273,1.5v3.205H132.5V23.254h1.373l.11.727a1.733,1.733,0,0,1,1.577-.825,1.8,1.8,0,0,1,1.773,1.117,2.163,2.163,0,0,1,2.007-1.092c1.7,0,2.536,1.044,2.536,2.84v3.228h-1.481V26.021c0-.788-.327-1.458-1.129-1.458a1.361,1.361,0,0,0-1.3,1.481v3.205Z"
        transform="translate(-43.387 -7.669)"
      />
      <path
        fill="#05051b"
        d="M150.217,20.39a.874.874,0,0,1-1.748,0A.874.874,0,0,1,150.217,20.39Zm-1.611,1.661V28.06h1.481V22.051Z"
        transform="translate(-48.617 -6.471)"
      />
      <path
        fill="#05051b"
        d="M157.243,29.256V26.123a1.428,1.428,0,0,0-1.444-1.6A1.577,1.577,0,0,0,154.27,26.2v3.059H152.8v-6h1.323l.1.813a2.7,2.7,0,0,1,1.967-.9c1.408,0,2.536,1.056,2.536,2.936v3.144Z"
        transform="translate(-50.035 -7.675)"
      />
      <path
        fill="#05051b"
        d="M168.052,22.415l1.1.827-.669.848a2.553,2.553,0,0,1,.633,1.773A2.374,2.374,0,0,1,167.85,28.1a2.014,2.014,0,0,1,1.238,1.979,2.7,2.7,0,0,1-3,2.671,2.748,2.748,0,0,1-3.034-2.671h1.469a1.416,1.416,0,0,0,1.565,1.286c.85,0,1.517-.46,1.517-1.286s-.769-1.2-1.517-1.2a2.8,2.8,0,0,1-3.034-3.009,2.918,2.918,0,0,1,3.034-3.034,2.384,2.384,0,0,1,1.371.34Zm-3.53,3.448a1.563,1.563,0,1,0,3.119,0,1.564,1.564,0,1,0-3.119,0Z"
        transform="translate(-53.394 -7.433)"
      />
      <path
        fill="#05051b"
        d="M174.951,19.6v3.313a2.478,2.478,0,0,1,1.965-.961,2.867,2.867,0,0,1,3.048,3.132,2.935,2.935,0,0,1-3.077,3.13,2.157,2.157,0,0,1-1.923-.961l-.1.825h-1.383V19.6Zm.1,5.486a1.719,1.719,0,1,0,3.436,0,1.651,1.651,0,0,0-1.7-1.748,1.708,1.708,0,0,0-1.736,1.748Z"
        transform="translate(-56.808 -6.497)"
      />
      <path
        fill="#05051b"
        d="M186.29,20.39a.874.874,0,0,1-1.748,0A.874.874,0,0,1,186.29,20.39Zm-1.615,1.661V28.06h1.481V22.051Z"
        transform="translate(-60.429 -6.471)"
      />
      <path
        fill="#05051b"
        d="M190.3,23.242l.11.692a1.763,1.763,0,0,1,1.686-.85,2.269,2.269,0,0,1,1.538.577l-.667,1.286a1.477,1.477,0,0,0-1.069-.385A1.383,1.383,0,0,0,190.4,26.08v3.155h-1.481V23.242Z"
        transform="translate(-61.864 -7.645)"
      />
      <path
        fill="#05051b"
        d="M202.845,19.6v8.484H201.46l-.1-.825a2.178,2.178,0,0,1-1.923.961,2.932,2.932,0,0,1-3.077-3.13,2.866,2.866,0,0,1,3.046-3.132,2.476,2.476,0,0,1,1.967.961V19.6Zm-5.013,5.486a1.722,1.722,0,1,0,1.7-1.748A1.651,1.651,0,0,0,197.832,25.088Z"
        transform="translate(-64.301 -6.497)"
      />
      <path
        fill="#05051b"
        d="M94.331,40.328H91.742v-.492h5.728v.492H94.882v6.659h-.552Z"
        transform="translate(-30.041 -13.197)"
      />
      <path
        fill="#05051b"
        d="M99.21,45.441a2.54,2.54,0,0,1,2.569-2.609,2.364,2.364,0,0,1,2.5,2.844H99.721a1.99,1.99,0,0,0,2.057,1.873,2.372,2.372,0,0,0,1.819-.769l.36.285a2.776,2.776,0,0,1-2.179.973A2.48,2.48,0,0,1,99.21,45.441Zm4.615-.206a1.853,1.853,0,0,0-2.044-1.944,2.022,2.022,0,0,0-2.057,1.944Z"
        transform="translate(-32.486 -14.181)"
      />
      <path
        fill="#05051b"
        d="M112.671,47.28a2.584,2.584,0,0,1-1.842.756,2.491,2.491,0,0,1-2.6-2.577,2.465,2.465,0,0,1,2.6-2.573,2.627,2.627,0,0,1,1.842.758l-.329.317a2.136,2.136,0,0,0-1.513-.6,2.115,2.115,0,1,0,0,4.23,2.14,2.14,0,0,0,1.5-.625Z"
        transform="translate(-35.441 -14.199)"
      />
      <path
        fill="#05051b"
        d="M116.838,39.821v3.048a2.02,2.02,0,0,1,1.819-1.013,1.992,1.992,0,0,1,2.115,2.231v2.9h-.492v-2.9c0-1.042-.49-1.75-1.627-1.75a1.783,1.783,0,0,0-1.8,1.873v2.773h-.488V39.821Z"
        transform="translate(-38.104 -13.192)"
      />
      <path
        fill="#05051b"
        d="M125.361,42.91v.9a2,2,0,0,1,1.8-.992,2.109,2.109,0,0,1,2.281,2.229v2.917h-.5V45.058a1.615,1.615,0,0,0-1.8-1.75,1.757,1.757,0,0,0-1.771,1.842v2.813h-.5V42.91Z"
        transform="translate(-40.889 -14.176)"
      />
      <path
        fill="#05051b"
        d="M133.265,45.446a2.569,2.569,0,1,1,5.136,0,2.568,2.568,0,1,1-5.136,0Zm4.644,0a2.078,2.078,0,1,0-4.153,0,2.077,2.077,0,1,0,4.153,0Z"
        transform="translate(-43.638 -14.176)"
      />
      <path
        fill="#05051b"
        d="M143.12,39.821v7.161h-.5V39.821Z"
        transform="translate(-46.702 -13.192)"
      />
      <path
        fill="#05051b"
        d="M145.1,45.446a2.568,2.568,0,1,1,5.134,0,2.567,2.567,0,1,1-5.134,0Zm4.644,0a2.078,2.078,0,1,0-4.153,0,2.077,2.077,0,1,0,4.153,0Z"
        transform="translate(-47.513 -14.176)"
      />
      <path
        fill="#05051b"
        d="M158.387,42.649l.329.287-.492.577a2.486,2.486,0,0,1-.736,3.867,1.851,1.851,0,0,1,1.381,1.811c0,1.359-1.063,2.015-2.4,2.015s-2.394-.736-2.394-2.015h.5c0,1.033.881,1.523,1.892,1.523s1.913-.45,1.913-1.523-.961-1.515-1.913-1.515a2.456,2.456,0,0,1,0-4.909,2.575,2.575,0,0,1,1.433.46Zm-3.794,2.579a1.852,1.852,0,0,0,1.871,1.944,1.94,1.94,0,0,0,1.892-1.944,1.818,1.818,0,0,0-1.892-1.965A1.782,1.782,0,0,0,154.593,45.228Z"
        transform="translate(-50.451 -14.135)"
      />
      <path
        fill="#05051b"
        d="M163.307,40.308a.4.4,0,1,1-.4-.4A.4.4,0,0,1,163.307,40.308Zm-.665,1.627v5.074h.5V41.935Z"
        transform="translate(-53.211 -13.219)"
      />
      <path
        fill="#05051b"
        d="M165.16,45.441a2.54,2.54,0,0,1,2.563-2.609,2.364,2.364,0,0,1,2.5,2.844h-4.551a1.988,1.988,0,0,0,2.052,1.873,2.376,2.376,0,0,0,1.821-.769l.358.285a2.776,2.776,0,0,1-2.179.973A2.474,2.474,0,0,1,165.16,45.441Zm4.615-.206a1.855,1.855,0,0,0-2.046-1.944,2.02,2.02,0,0,0-2.056,1.944Z"
        transform="translate(-54.082 -14.181)"
      />
      <path
        fill="#05051b"
        d="M177.945,43.827a2.16,2.16,0,0,0-1.575-.531c-.788-.012-1.538.287-1.525.931.021.675.9.808,1.538.921.9.154,2.138.306,2.086,1.483-.031,1.115-1.186,1.411-2.077,1.411a2.6,2.6,0,0,1-2.209-1.011l.369-.329a2.239,2.239,0,0,0,1.85.86c.615,0,1.538-.163,1.577-.961.019-.727-.819-.869-1.648-1-.981-.163-1.954-.348-1.963-1.361s.983-1.421,2.006-1.411a2.648,2.648,0,0,1,1.881.675Z"
        transform="translate(-57.037 -14.178)"
      />
    </svg>
  );
}

export default HummingbirdLogo;
