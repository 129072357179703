import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { SentryRoute } from 'services/sentry';
import Logout from './pages/Logout';
import RefreshToken from './pages/RefreshToken';
import MRVToken from './pages/MRVToken';

const Login = lazy(() => import('./pages/Login'));
const ForgottenPassword = lazy(() => import('./pages/ForgottenPassword'));
const SetPassword = lazy(() => import('./pages/SetPassword'));

function AppRouter(): React.ReactElement {
  return (
    <Router>
      <Suspense fallback={<>Loading...</>}>
        <Switch>
          <SentryRoute path="/login">
            <Login />
          </SentryRoute>
          <SentryRoute path="/forgotten-password">
            <ForgottenPassword />
          </SentryRoute>
          <SentryRoute path="/refresh-token">
            <RefreshToken />
          </SentryRoute>
          <SentryRoute path="/new-user/set-password/:uid/:token">
            <SetPassword />
          </SentryRoute>
          <SentryRoute path="/mrv-token">
            <MRVToken />
          </SentryRoute>
          <SentryRoute exact path="/logout">
            <Logout />
          </SentryRoute>
          <Route exact path="*">
            <Redirect to="/login" />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
}

export default AppRouter;
