import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';

import AccountLayout from 'components/AccountLayout/AccountLayout';
import { loadState } from 'services/statePersist/localStore';
import { TOKEN_KEY } from 'constants/constants';
import { H1, MediaQueries } from 'libraries/ui';
import useClients from './useClients';
import TokenItem from './TokenItem';
import useLoginRedirect from './useLoginRedirect';

const useStyles = createUseStyles({
  pageTitle: {
    marginTop: 32,
  },
  form: {
    marginTop: 20,
    overflow: 'hidden',
  },
  input: {
    marginTop: 16,
  },
  button: {
    marginTop: 16,
  },
  [MediaQueries.DESKTOP]: {
    pageTitle: {
      marginTop: 136,
    },
  },
});

function MRVToken() {
  const classes = useStyles();
  const loginRedirect = useLoginRedirect();
  const { data: clients } = useClients();
  const storedAuthToken = loadState(TOKEN_KEY);
  useEffect(() => {
    if (!storedAuthToken) loginRedirect();
  }, [loginRedirect, storedAuthToken]);

  return (
    <AccountLayout>
      <H1 className={classes.pageTitle} variant="jumbo" theme="bold">
        <FormattedMessage id="user.generateToken" defaultMessage="MRV Token" />
      </H1>
      <section>
        {clients?.map(client => (
          <TokenItem client={client} />
        ))}
      </section>
    </AccountLayout>
  );
}

export default MRVToken;
