export default function request<T>(
  method: string,
  domain: string,
  url: string,
  data?: Record<string, unknown>,
  headers?: HeadersInit,
): Promise<T> {
  return fetch(`${domain}${url}`, {
    method,
    headers: { 'Content-Type': 'application/json', ...headers },
    body: data && JSON.stringify(data),
  })
    .then(response => {
      if (!response.ok) {
        const error =
          (data && data.message) ||
          response.statusText ||
          response.status.toString();
        return Promise.reject(error);
      }
      return response;
    })
    .then(res => res.text())
    .then(text => (text ? JSON.parse(text) : {}));
}

export function get<T>(domain: string, url: string): Promise<T> {
  return request('GET', domain, url);
}

export function put<T>(
  domain: string,
  url: string,
  data: Record<string, unknown> = {},
): Promise<T> {
  return request('PUT', domain, url, data);
}

export function post<T>(
  domain: string,
  url: string,
  data: Record<string, unknown> = {},
): Promise<T> {
  return request('POST', domain, url, data);
}
