import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  ConfigProvider,
  AppEnvironment,
} from '@hummingbirdtechgroup/wings-config';
import { ConfiguredIntlProvider } from 'services/i18n';
import { SentryScope } from 'services/sentry';
import { useIntercomMessenger } from 'services/intercomMessenger';
import AppRouter from './AppRouter';
import EmailProvider from './services/email/EmailProvider';
import { fontStyles, resetStyles } from './styles';
import GoogleAnalytics from './libraries/analytics/GoogleAnalytics';

const useStyles = createUseStyles(() => ({
  '@global': {
    ...fontStyles,
    ...resetStyles,
  },
}));

function App() {
  useIntercomMessenger();
  useStyles();

  const apiEnv = process.env.API_ENV as AppEnvironment;

  return (
    <ConfigProvider env={apiEnv}>
      <>
        <AppRouter />
        {apiEnv === 'production' && (
          <>
            <SentryScope />
            <GoogleAnalytics />
          </>
        )}
      </>
    </ConfigProvider>
  );
}

function AppWithProviders(): React.ReactElement {
  return (
    <ConfiguredIntlProvider>
      <EmailProvider>
        <App />
      </EmailProvider>
    </ConfiguredIntlProvider>
  );
}

export default AppWithProviders;
